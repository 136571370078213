import { createSlice } from "@reduxjs/toolkit";

export interface IMasterState {
  master: any;
  loader: boolean;
}

const initialState: IMasterState = {
  master: {},
  loader: false,
};

const MasterSlice = createSlice({
  name: "master",
  initialState,
  reducers: {
    getMasterData: (state, action) => ({
      ...state,
      master: {
        ...state.master,
        ...action.payload,
      },
    }),
    setLoader: (state, action) => ({
      ...state,
      loader: action.payload,
    }),
    clearMaster: (state) => ({
      ...state,
      master: {},
      loader: false,
    }),
  },
});

export const { getMasterData, setLoader, clearMaster } = MasterSlice.actions;
export default MasterSlice;
